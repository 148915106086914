@media only screen and (max-width: 768px) and (orientation: portrait) {
  .wrapper {
    .mainLayout {
      flex-direction: column-reverse;

      .ant-layout-content {
        width: 100%;
        height: calc(100% - 60px);
      }

      .sidebar {
        min-width: 100% !important;
        max-width: 100% !important;
        width: 100% !important;
        height: 60px;
        flex: 1 !important;

        .ant-layout-sider-children {
          height: 60px;
          .sidebar-trigger-container {
            display: none;
          }
          .sidebar-items-container {
            border: none;
            border-top: 1px solid rgb(225, 225, 225);
            height: 100%;
            ul {
              width: 100%;
              height: 100%;
              display: flex;
              li {
                height: 100%;
                display: flex;
                flex-direction: column;
                margin: 0;
                justify-content: center;
                align-items: center;
                font-size: 12px;

                .anticon {
                  min-width: 20px;
                  font-size: 20px;
                }

                .ant-menu-title-content {
                  margin-inline-start: 0px;
                  height: fit-content;
                  line-height: 12px;
                  margin: 8px 0px 0px 0px;
                }
              }
            }
          }
        }
      }
    }

    .signup-wrapper {
      .header-wrapper {
        height: 100px;

        .logo-container,
        .signup-nav {
          flex-wrap: wrap;
        }

        .logo-container {
          img {
            width: 120px;
          }
        }

        .signup-nav {
          h5 {
            margin-top: 14px;
          }
          .signIn-btn {
            margin-top: 14px;
          }
        }
      }
      .signup-middle {
        height: calc(100% - 100px);

        .signup-success-wrapper {
          .verify-btn {
            padding: 0px 55px;
          }

          .ant-result {
            width: 90%;
            .ant-result-title {
              font-size: 19px;
            }
          }
        }
      }
    }

    .dashboard-container,
    .design-container,
    .document-container {
      height: 100%;

      .header-wrapper {
        .logo-container {
          img {
            width: 90px;
          }
        }
      }
    }

    .design-container {
      .editor-container {
        .form-header {
          .ant-typography {
            font-size: 22px;
            .ant-typography-edit {
              // margin-left: 24px;
            }
          }
        }

        .form-container {
          .form-content {
            .pdf-list-container {
              flex-direction: column;
              height: 100%;
              .pdf-list {
                max-width: 100%;
                width: 100%;
                align-items: flex-start;
                height: 100px;
                flex: none;
                border: none;
                border-bottom: 1px solid rgb(216, 216, 216);
                background-color: #fff;
                padding: 12px 16px;
                flex-direction: row;
                justify-content: space-between;

                .pdf-item-upload {
                  max-width: 85px;
                  height: 100%;
                }

                .pdf-items {
                  width: calc(100% - 100px);
                  height: 100%;
                  overflow-y: hidden;
                  overflow-x: auto;
                  display: flex;

                  .pdf-item-wrap {
                    width: 80px;
                    height: 100%;
                    margin-bottom: 0px;
                    margin-left: 10px;
                    .anticon {
                      margin-bottom: 5px;
                    }
                  }
                }
              }
              .pdf-viewer-container {
                width: 100%;
                height: calc(100% - 100px);
                .pdf-toolbar,
                .pdf-viewer-area {
                  height: 100%;
                }
              }
            }
          }
        }
      }
    }

    .document-container {
      .editor-container {
        .editor-wrap {
          .pdf-doc-view {
            flex-direction: column;
            .dashboard-container {
              max-width: 100%;
              height: fit-content;
              max-height: 230px;
              .dashboard-content {
                padding-bottom: 0px;
              }
            }

            .pdf-list {
              max-width: 100%;
              width: 100%;
              align-items: flex-start;
              height: 100px;
              flex: none;
              border: none;
              border-bottom: 1px solid rgb(216, 216, 216);
              background-color: #fff;
              padding: 12px 16px;
              flex-direction: row;
              justify-content: space-between;

              .pdf-item-upload {
                max-width: 85px;
                height: 100%;
              }

              .pdf-items {
                width: calc(100% - 100px);
                height: 100%;
                overflow-y: hidden;
                overflow-x: auto;
                display: flex;

                .pdf-item-wrap {
                  width: 80px;
                  height: 100%;
                  margin-bottom: 0px;
                  margin-left: 10px;
                  .anticon {
                    margin-bottom: 5px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
