@use "./styles/styles.scss";
@use "./styles/common.scss";
@use "./styles/font.scss";

.wrapper {
  height: inherit;

  .mainLayout {
    height: 100%;

    .sidebar {
      .sidebar-trigger-container,
      .sidebar-items-container {
        border-right: 1px solid rgb(225, 225, 225);
      }

      .sidebar-trigger-container {
        @extend .center-all;
        justify-content: flex-end;
        padding: 0px 16px;
        height: 80px;
        border-bottom: 1px solid rgb(225, 225, 225);

        .sidebar-trigger {
          background-color: transparent;
          border: none;
          outline: none;
          width: 40px;
          height: 40px;
          cursor: pointer;
          transition: all 0.5s ease;

          .sidebar-trigger-icon {
            font-size: 25px;
            color: rgb(55, 55, 55);
          }
        }
      }

      .sidebar-items-container {
        height: calc(100% - 80px);
      }
    }

    .ant-layout-content {
      height: 100%;
    }
  }

  .signup-wrapper {
    height: inherit;

    .header-wrapper {
      @extend .center-all;
      height: 80px;

      .logo-container,
      .signup-nav {
        display: flex;
        padding: 0px 24px;
      }

      .logo-container {
        flex: 2;

        img {
          width: 170px;
        }
      }

      .signup-nav {
        @extend .center-all;
        justify-content: flex-end;
        flex: 8;

        .signIn-btn {
          margin-left: 15px;
        }
      }
    }

    .signup-middle {
      @extend .center-all;
      height: calc(100% - 80px);

      .signup-card {
        width: 370px;
        max-width: 370px;
        margin-bottom: 10%;

        .forgot-img,
        .forgot-status-img,
        .reset-status-img,
        .reset-form-img {
          background-image: url("../public/assets/key.png");
          background-repeat: no-repeat;
          background-size: contain;
          background-position: center;
          width: 70px;
          height: 70px;
          margin: auto;
        }

        .forgot-status-img {
          background-image: url("../public/assets/email.png");
        }

        .reset-status-img {
          background-image: url("../public/assets/checked.png");
        }

        .reset-form-img {
          background-image: url("../public/assets/locked.png");
        }

        .signup-card-head {
          text-align: center;
          margin-bottom: 30px;
        }

        .signup-card-sub-head {
          display: flex;
          justify-content: center;
          color: styles.$greyDark;
          margin-bottom: 30px;
          text-align: center;
        }

        .ant-form-item.forgot-container {
          margin-bottom: 12px;

          .ant-row.ant-form-item-row {
            .ant-col.ant-form-item-control {
              .ant-form-item-control-input {
                .ant-form-item-control-input-content {
                  display: flex;
                  justify-content: flex-end;
                }
              }
            }
          }
        }

        .forgot-pass {
          // background-color: red;
        }

        .signup-btn {
          width: 100%;
          height: 40px;
        }

        .ant-form-item {
          margin-bottom: 30px;
        }

        .pass-container {
          margin-bottom: 12px;
        }

        .name-group {
          @extend .center-all;
          justify-content: space-between;

          .ant-form-item {
            width: 47%;
          }
        }
      }

      .signup-success-wrapper {
        height: inherit;
        @extend .center-all;
        flex-direction: column;
        margin-bottom: 10%;

        .verify-btn {
          padding: 0px 40px;
          height: 35px;
        }

        .ant-result {
          .ant-result-title {
            margin: 30px 0px;
            font-size: 22px;
          }
        }

        .success-container {
          width: 60%;

          .code-input-container {
            @extend .center-all;

            height: 60px;

            .code-inp {
              @extend .center-all;
              border-radius: 50%;
              border: 2px dashed styles.$blue;
              width: 55px;
              height: 55px;
              margin: 15px;

              input {
                width: 50px;
                height: 50px;
                background-color: transparent;
                border: none;
                text-transform: capitalize;
                font-size: 25px;
                outline: none;
                text-align: center;
                font-weight: 700;
                caret-color: transparent;
                color: styles.$blue;
              }
            }

            .code-inp:focus-within {
              background-color: #ececec;
            }
          }
        }
      }

      .forgot-stat-card {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        .resend-btn {
          min-width: 120px;
        }
      }
    }
  }

  .dashboard-container,
  .design-container,
  .document-container {
    height: inherit;

    .header-wrapper {
      @extend .center-all;
      height: 80px;
      cursor: pointer;
      background-color: #fff;

      .logo-container,
      .dash-nav {
        display: flex;
        padding: 0px 24px;
      }

      .logo-container {
        flex: 2;

        img {
          width: 170px;
        }
      }

      .dash-nav {
        @extend .center-all;
        justify-content: flex-end;
        flex: 8;

        .new-btn {
          //
        }
      }

      .doc-note {
        position: absolute;
        font-size: 12px;
        transform: translate(-50%, 0%);
        left: 50%;
        overflow: hidden;
      }
    }

    .dashboard-content {
      height: calc(100% - 80px);
      padding: 24px;

      .ant-row {
        .ant-col {
          min-width: 220px;

          .card-body {
            .ant-space {
              .ant-space-item {
                .custom-badge {
                  .ant-badge-status-dot {
                    width: 8px;
                    height: 8px;
                  }
                }
              }
            }
          }
        }
      }

      // sign space container styles
      .sign-space-container {
        .ant-space-item {
          width: 100%;
          .ant-empty {
            @extend .center-all;
            flex-direction: column;
            svg {
              width: 50px;
              height: 45px;
            }
          }
        }

        .ant-space-item:nth-of-type(1) {
          .ant-card {
            .ant-card-head-title {
              .card-title-custom {
                flex-wrap: wrap;
                @extend .center-all;
                justify-content: space-between;
              }
            }
            .ant-card-body {
              .curr-sign-wrap {
                width: 100%;
                @extend .center-all;
                justify-content: space-evenly;

                .sign-name-wrap {
                  width: 46%;
                  height: 80px;
                }
              }
            }
          }
        }

        .ant-space-item:nth-of-type(2) {
          width: 100%;
          height: calc(100% - 220px);
          .ant-space {
            height: 100%;
            .ant-space-item:nth-of-type(2) {
              height: calc(100% - 40px);
              .ant-tabs {
                .ant-tabs-content-holder {
                  height: inherit;
                  .ant-tabs-content {
                    height: inherit;
                    .ant-tabs-tabpane.ant-tabs-tabpane-active {
                      height: inherit;

                      .ant-space {
                        // signature full name styles
                        .ant-space-item:nth-of-type(1) {
                          width: 100%;
                          .signature-name-wrap {
                            flex-wrap: wrap;

                            .ant-space-item {
                              width: fit-content;
                              // height: 100%;
                              height: fit-content;
                            }
                          }
                        }

                        // signature list ui styles
                        .ant-space-item:nth-of-type(2) {
                          .ant-list {
                            height: calc(100% - 24px);
                            .ant-spin-nested-loading {
                              height: 100%;
                              .ant-spin-container {
                                height: inherit;
                                overflow-y: auto;
                                .ant-list-items {
                                  .ant-list-item {
                                    .sign-list-radio-wrap {
                                      @extend .center-all;

                                      .ant-space-item {
                                        margin: 0px 10px;
                                      }

                                      .ant-space-item:nth-of-type(1) {
                                        flex: 1;
                                        max-width: 30px;
                                      }
                                      .ant-space-item:nth-of-type(2) {
                                        flex: 3;
                                        .sign-name-wrap {
                                          width: 300px;
                                          height: 60px;
                                        }
                                      }
                                      .ant-space-item:nth-of-type(3) {
                                        flex: 3;
                                        .sign-name-wrap {
                                          width: 85px;
                                          height: 60px;
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }

                      // sign upload styles
                      .sign-upload-wrap {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        flex-direction: column;

                        .ant-space-item:nth-of-type(2) {
                          overflow-y: auto;
                          overflow-x: hidden;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .form-status-container {
      background-color: #f9f9f9;

      .workflow-status-empty {
        width: 450px;
        @extend .center-all;
        flex-direction: column;
        text-align: center;

        .para-style {
          color: grey;
        }
      }
    }
  }

  .dashboard-container {
    .metrics-wrap {
      padding: 24px;
      height: calc(100% - 80px);

      .metrics-container {
        color: grey;
        border: 1px solid rgba(0, 0, 0, 0.1);
        background-color: white;
        border-radius: 10px;
        @extend .center-all;
        justify-content: space-evenly;
        max-height: 200px;

        .action-need,
        .waiting-others,
        .expiring,
        .completed,
        .expired {
          padding: 16px;
          max-width: 230px;
          max-height: 200px;
          height: 160px;
          width: 100%;
          @extend .center-all;
          flex-direction: column;

          .ant-typography {
            font-size: 40px;
          }

          span {
            margin-top: 10px;
            font-size: 14px;
            font-weight: 700;
            text-align: center;
            overflow: hidden;
            text-overflow: ellipsis;
            text-transform: capitalize;
            max-width: 198px;
            white-space: nowrap;
          }
        }

        .ant-divider {
          height: 90px;
          background-color: #1677ff;
          border-width: 2px;
        }

        .action-need {
        }

        .waiting-others {
        }

        .expiring {
        }
      }

      .metrics-export {
        @extend .center-all;
        justify-content: flex-end;
      }

      .metrics-history {
        @extend .center-all;
        flex-direction: column;
        justify-content: space-between;
        margin-top: 20px;
        height: calc(100% - 230px);
        overflow: hidden;

        .ant-table-wrapper {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  .design-container {
    .editor-container {
      height: calc(100% - 80px);
      width: 100%;
      @extend .center-all;
      flex-direction: column;
      justify-content: flex-start;

      .form-header {
        padding: 0px 24px;
        @extend .center-all;
        justify-content: flex-start;
        height: 40px;
        width: inherit;
        margin-bottom: 4px;

        .ant-typography {
          white-space: nowrap;

          .ant-typography-edit {
            margin-left: 24px;
          }
        }
      }

      .form-container {
        height: calc(100% - 40px);
        width: inherit;
        @extend .center-all;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        background-color: #f0f8ff4f;

        .form-content {
          width: inherit;
          overflow-y: auto;
          border-top: 1px solid rgb(205, 205, 205);
          height: 100%;

          .pdf-list-container {
            height: inherit;
            @extend .center-all;
            justify-content: flex-start;
          }
        }
      }
    }

    .header-wrapper {
      .dash-nav {
        .already-publish {
          margin-inline-end: 45%;
        }
      }
    }
  }

  .document-container {
    .header-wrapper {
      border-bottom: 1px solid rgb(205, 205, 205);
    }

    .editor-container {
      max-height: calc(100% - 80px);
      width: 100%;
      margin: 0 auto;
      overflow-y: auto;
      height: 100%;

      .editor-wrap {
        display: flex;
        height: inherit;
        width: 100%;

        #editorjs {
          position: absolute;
          left: 55%;
          margin-top: 50px;
          transform: translate(-50%);
        }

        .pdf-doc-view {
          display: flex;
          width: inherit;
          height: inherit;

          .dashboard-container {
            flex: 1;
            max-width: 300px;
            height: inherit;

            .dashboard-content {
              height: inherit;
              .form-status-container {
                //
              }
            }
          }

          .pdf-viewer-container {
            flex: 4;
          }
        }
      }
    }

    // pdf export loader styles
    .pdf-export-loader {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      -webkit-backdrop-filter: saturate(180%) blur(20px);
      backdrop-filter: saturate(180%) blur(20px);
      background-color: rgba(0, 0, 0, 0.1);
      @extend .center-all;
      z-index: 30;

      .pdf-export-content {
        min-width: 300px;
        width: 300px;
        max-width: 450px;

        min-height: 200px;
        height: 200px;
        max-height: 300px;

        padding: 14px;

        background-color: #fff;
        border-radius: 10px;
        border: 1px solid #f5f5f5;

        .loader-lottie {
          margin-top: 5%;
          width: 100%;
          height: 40%;
          overflow: hidden;
          @extend .center-all;
        }
        p {
          color: grey;
          text-align: center;
        }
      }
    }
  }

  .overlay-container {
    @extend .center-all;
    background-color: white;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
}

// notifications styles
.ant-popover {
  .ant-popover-content {
    .ant-popover-inner {
      padding: 0px;
      .ant-popover-title {
        border-bottom: 1px solid rgb(205, 205, 205);
        padding: 10px;
        margin: 0;
        .noti-title {
          display: flex;
          justify-content: space-between;
        }
      }
      .ant-popover-inner-content {
        .notifications-wrap {
          width: 300px;
          max-width: 400px;
          height: 300px;
          max-height: 400px;
          @extend .center-all;
          overflow-y: auto;

          .noti-list {
            padding: 20px;
            border-bottom: 1px solid rgb(205, 205, 205);

            .noti-header {
              display: flex;
              align-items: flex-start;
              justify-content: space-between;
              .noti-title {
                margin-bottom: 10px;
                margin-right: 5px;
              }
            }

            .noti-desc {
              font-size: 12px;
              color: gray;
              margin-bottom: 7px;
            }

            .noti-action {
              display: flex;
              justify-content: space-between;
              align-items: baseline;

              .time-container {
              }
              .ant-btn {
                // padding: 0;
              }
            }
          }

          .noti-list:hover {
            background-color: #f1f1f1;
          }
        }
      }
    }
  }
}

// remarks popover styles
.remarks-popover {
  .ant-popover-content {
    .ant-popover-inner {
      padding: 16px;
    }
  }
}

// avatar menu styles
.avatar-menu-container {
  width: 250px;
  min-width: fit-content;
  @extend .center-all;
  flex-direction: column;
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  border: 1px solid styles.$grey;

  .ant-avatar.avatar-icon {
    margin-top: 15px;
  }

  .ant-typography.greet-title {
    margin-top: 10px;
    font-weight: 650;
  }

  .avatar-greet {
    @extend .center-all;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: column;
  }

  .ant-typography.greet-name {
    margin: 14px 0 0 5px;
    font-weight: 550;
  }

  .ant-divider.greet-divider {
    margin: 12px 0;
  }

  .greet-logout {
    color: styles.$red;
    font-weight: 550;
  }

  .greet-logout:hover {
    border-color: styles.$red;
    color: styles.$red;
  }
}

// publish modal styles
.publish-content {
  //
  .empty-publish-container {
    margin: 15px 0px 20px 0px;
    min-height: 280px;
    // overflow-y: hidden;
    border-radius: 6px;
    border: 1px dashed styles.$grey;
    padding: 14px;
    overflow-y: auto;

    .order-wrap {
      @extend .center-all;

      .order-action {
        @extend .center-all;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background-color: #1677ff;
        color: white;
        margin: 0px 10px 8px 0px;
      }

      .email-tag {
        margin-bottom: 8px;
        padding: 8px 32px;
        margin: 0 0 8px 0;
        @extend .center-all;
        justify-content: flex-start;

        .role-tag {
          font-size: 10px;
          margin: 0px 10px 0px 0px;

          .ant-badge-status-dot {
            width: 8px;
            height: 8px;
          }
        }

        .role-tag:nth-of-type(1) {
          margin: 0px 10px 0px 10px;
        }

        .role-tag:last-of-type {
          margin: 0px 0px 0px 10px;
        }
      }
    }

    .empty-publish {
      //
    }
  }

  .email-content {
    width: 100%;

    .email-inp-container {
      width: 100%;

      .ant-space-item:nth-of-type(1) {
        width: 100%;
      }
    }

    .email-error {
      margin: 5px 0px 0px 8px;
      font-size: 12px;
      color: styles.$red;
    }

    .email-role {
      margin: 0px 0px 0px 0px;
    }
  }

  .signature-content {
    margin-top: 14px;
  }

  .info-bar {
    margin-top: 7px;

    .ant-space-item {
      .ant-badge {
        .ant-badge-status-dot {
          width: 10px;
          height: 10px;
        }

        .ant-badge-status-text {
          font-size: 10px;
        }
      }
    }
  }
}

// approve container modal
.approve-container {
  //
}

.signature-selected {
  border: 3px solid styles.$blue !important;
  border-radius: 12px !important;
}

// approval table editor styles
.status-subtitle {
  font-size: 11px;
  white-space: nowrap;
}

// flow dialog styles
.flow-type-container {
  height: 200px;
  @extend .center-all;

  .ant-btn {
    width: 200px;
    height: 120px;
    margin: 10px;
    font-size: 18px;
    font-weight: 700;
    border-color: #1677ff86;
    color: #1677ff86;
    border-style: dashed;
    border-width: 2px;
  }

  .ant-btn:active,
  .ant-btn:hover {
    border-style: solid;
  }
}

// pdf editor tool styles
.pdf-tool-selected {
  border: 2px solid #1677ff !important;
}

.pdf-viewer-container {
  @extend .center-all;
  height: 100%;
  flex: 4;

  .pdf-toolbar {
    flex: 1;
    max-width: 200px;
    height: inherit;
    border-right: 1px solid rgb(205, 205, 205);
    background-color: #fff;

    .page-nav {
      @extend .center-all;
      justify-content: space-evenly;
      white-space: nowrap;
      overflow: hidden;

      button {
        padding: 4px 10px;
      }
    }

    .shown-reci {
      @extend .center-all;
      flex-direction: column;
      .ant-badge {
        width: calc(100% - 20px);
        height: 6px;
        display: flex;
        .ant-badge-status-dot {
          width: 100%;
          height: 4px;
          border-radius: 0px;
        }
      }
    }

    .designated-boxes {
      .ant-badge {
        .ant-badge-status-dot {
          height: 8px;
        }
      }
    }

    .page-widgets {
      padding: 5px 20px;
      min-height: 100px;
      height: fit-content;
      width: 100%;

      .tool-icons {
        border: 1px solid #d9d9d9;
        padding: 8px;
        width: 35px;
        height: 35px;
        cursor: pointer;
        border-radius: 5px;
      }

      .tool-icons:hover {
        opacity: 0.8;
      }
    }

    .tool-props {
      .ant-space {
        width: 100%;
        .ant-space-item {
          width: inherit;

          .inp-color-picker {
            width: 100%;
            display: flex;
            padding: 0;
            border: none;
            margin: 0;
            outline: 0;
            height: 30px;
            border-radius: 10px;
            appearance: none;
            background-color: transparent;
          }

          .empty-props {
            .ant-empty-description {
              font-size: 12px;
            }
          }
        }
      }
    }
  }

  .pdf-viewer-area {
    flex: 3;
    overflow-y: auto;
    height: inherit;
    background-color: rgb(240 248 255);

    @extend .center-all;
    align-items: flex-start;

    .pdf-editor {
      position: absolute;
      top: 0;
      border: 1px solid rgb(238, 238, 238);
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .ant-skeleton {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

// signature dialog styles
.signature-dialog {
  background-color: rgba(0, 0, 0, 0.7);
  position: absolute;
  @extend .center-all;

  z-index: 99;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;

  .ant-space {
    background-color: white;
    width: 800px;
    height: 400px;
    border-radius: 10px;
    padding: 24px;

    .ant-space-item {
      flex: 0;
    }

    .ant-space-item:nth-of-type(2) {
      overflow-y: auto;
      flex: 2;

      .ant-upload-list.ant-upload-list-picture-card {
        display: grid;
        column-gap: 10px;
        row-gap: 10px;
        grid-template-columns: repeat(6, minmax(80px, 100px));
        grid-template-rows: repeat(3, 100px);
        justify-self: start;
        height: fit-content;
        grid-auto-flow: dense;

        .ant-upload {
          grid-column-start: 1;
          grid-column-end: 2;
          grid-row-start: 1;
          grid-row-end: 1;
        }
      }

      .ant-upload-list.ant-upload-list-picture-card::before {
        content: none;
      }
    }

    .ant-space-item:nth-of-type(3) {
      .sign-dialog-footer {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        .ant-btn {
          margin: 10px;
        }
      }
    }
  }
}

// pdf upload container styles
.pdf-upload-container {
  min-height: 200px;
  @extend .center-all;
  margin-bottom: 20px;
  margin-top: 140px;
  flex-direction: column;

  .pdf-content-wrap {
    min-height: inherit;
    max-width: 650px;
    width: 100%;
    border: 2px dashed #1677ff;
    border-radius: 10px;
    @extend .center-all;
    flex-direction: column;

    .anticon-file-pdf {
      font-size: 35px;
      color: #1677ff;
      margin: 14px;
    }
  }
}

.upload-pdf-modal {
  .pdf-upload-container {
    margin-top: 24px;
  }
}

// pdf list style
.pdf-list {
  flex: 1;
  max-width: 130px;
  height: inherit;
  @extend .center-all;
  flex-direction: column;
  justify-content: flex-start;
  padding: 16px;
  border-right: 1px solid rgb(216, 216, 216);

  .pdf-item-upload {
    border-radius: 10px;
    height: 90px;
    width: 100%;
    padding: 10px;
    @extend .center-all;
    flex-direction: column;
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.04);
    border: 1px solid rgb(216, 216, 216);
    margin-bottom: 10px;

    .anticon-plus {
      @extend .center-all;
      font-size: 20px;
      margin-bottom: 10px;
    }

    span {
      font-size: 12px;
    }
  }

  .pdf-items {
    width: 100%;
    height: calc(100% - 100px);
    overflow-y: auto;

    .pdf-item-wrap {
      border-radius: 10px;
      height: 90px;
      width: 100%;
      max-width: 100%;
      padding: 4px 10px 10px 10px;
      @extend .center-all;
      flex-direction: column;
      cursor: pointer;
      margin-bottom: 10px;

      .pdf-name {
        font-size: 11px;
        text-overflow: ellipsis;
        max-width: inherit;
        white-space: nowrap;
        overflow: hidden;
      }

      .anticon {
        font-size: 15px;
        margin-bottom: 10px;
      }

      .ant-space {
        .ant-space-item {
          .pdf-edit-btn,
          .pdf-delete-btn {
            font-size: 15px;
            width: 17px;
            height: 17px;
            margin-bottom: 5px;
            color: rgb(63, 63, 63);
          }
        }
      }
    }

    .pdf-item-wrap:last-of-type {
      margin: 0;
    }
  }
}

// signature name component styles
.sign-name-wrap {
  @extend .center-all;
  height: 100%;
  width: 100%;

  .sign-content-wrap {
    @extend .center-all;
    align-items: flex-start;
    flex-direction: column;
    height: 100%;
    width: calc(100% - 10px);
    flex: 1;

    .signed-by {
      min-height: 20%;
      @extend .center-all;
      width: 100%;
      justify-content: flex-start;
      .signed-by-lock {
        display: flex;
        justify-content: flex-start;
        width: 40%;
        min-width: fit-content;
        @extend .center-all;
        img {
          max-width: 15px;
          max-height: 15px;
          width: 15%;
          height: 15%;
        }
        span {
          font-size: 12px;
          color: gray;
          margin: 0px 4px;
          white-space: nowrap;
        }
      }
      .border-rs-vert {
        display: flex;
        align-items: center;
        flex: 1;
        width: 60%;
        min-height: 100%;

        span {
          width: 100%;
          border-bottom: 2px solid gray;
        }
      }
    }

    .sign-name {
      @extend .center-all;
      width: 100%;
      min-height: 60%;
      overflow: hidden;
      p {
        font-size: 16px;
        margin: 4px 0px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .sign-serial {
      @extend .center-all;
      justify-content: flex-start;
      flex: 1;
      width: 100%;
      // height: 20px;
      min-height: 20%;
      .serial {
        font-size: 10px;
        color: gray;
        margin: 0px 4px;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 70%;
        width: 70%;
        overflow: hidden;
      }
      .border-rs-hz {
        display: flex;
        align-items: center;
        max-width: 30%;
        width: 30%;
        height: 100%;
        span {
          display: block;
          width: 100%;
          border-top: 2px solid gray;
        }
      }
    }
  }

  .sign-border-curve {
    @extend .center-all;
    width: 10px;
    height: 100%;

    span {
      display: block;
      width: inherit;
      height: 80%;
      border-inline-end: 2px solid gray;
      border-start-start-radius: 0px;
      border-start-end-radius: 7px;
      border-end-end-radius: 7px;
      border-end-start-radius: 0px;
      border-top: 2px solid gray;
      border-bottom: 2px solid gray;
    }
  }
}

// signature initial styles
.sign-initial-wrap {
  width: 100%;

  .sign-content-wrap {
    width: calc(100% - 10px);
    .signed-by {
      .signed-by-lock {
        width: 100%;
      }
      .border-rs-vert {
        display: none;
      }
    }

    .sign-serial {
      max-width: 100%;
      width: 100%;
      .serial {
        max-width: 100%;
      }
      .border-rs-hz {
        display: none;
      }
    }
  }
}

// widget overlay
.widget-overlay {
  width: 100%;
  height: 100%;

  .widget-content {
    z-index: 20;
    transition: transform 0.01s ease-in-out;
    @extend .center-all;
    flex-direction: column;
    display: flex !important;

    span,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    hr,
    table {
      width: inherit;
      flex: 1;
    }

    p {
      @extend .center-all;
      margin: 0;
    }

    img {
      width: 22%;
      height: 34%;
      object-fit: contain;
      object-position: center;
      font-size: 20%;
    }

    // signature not available
    .signature-not-config {
      width: 100%;
      height: 100%;
      background-color: #fff9e8;
      border: 1px solid #ffbf00;
      @extend .center-all;
      flex-direction: column;
      border-radius: 7px;
      cursor: pointer;

      img {
        width: 45%;
        height: 45%;
      }
      p {
        margin: 4px 0 0 0;
        font-size: 10px;
        font-weight: 450;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 100%;
        padding: 0px 5px;
      }
    }
  }
}

// include media breakpoints after main styles
@import "./styles/breakpoints/point-425.scss";
@import "./styles/breakpoints/point-560.scss";
@import "./styles/breakpoints/point-768.scss";
