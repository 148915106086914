//variables
$green: #0faf51;
$amber: #FFBF00;
$blue: #1677ff;
$grey: #dedede;
$red: #D22B2B;
$greyDark: #a0a0a0;



.center-all {
    display: flex;
    justify-content: center;
    align-items: center;
}

.no-margin {
    margin: 0;
    margin-bottom: 0 !important;
}

.full-width {
    width: 100%;
}

