@font-face {
  font-family: "Babylonica-Regular";
  src: local("Babylonica-Regular"),
    url("../../public/assets/fonts/Babylonica-Regular.woff2") format("woff2");
}

@font-face {
  font-family: "BethEllen-Regular";
  src: local("BethEllen-Regular"),
    url("../../public/assets/fonts/BethEllen-Regular.woff2") format("woff2");
}

@font-face {
  font-family: "Caveat-VariableFont_wght";
  src: local("Caveat-VariableFont_wght"),
    url("../../public/assets/fonts/Caveat-VariableFont_wght.woff2") format("woff2");
}

@font-face {
  font-family: "Charm-Regular";
  src: local("Charm-Regular"),
    url("../../public/assets/fonts/Charm-Regular.woff2") format("woff2");
}

@font-face {
  font-family: "ComforterBrush-Regular";
  src: local("ComforterBrush-Regular"),
    url("../../public/assets/fonts/ComforterBrush-Regular.woff2") format("woff2");
}

@font-face {
  font-family: "Estonia-Regular";
  src: local("Estonia-Regular"),
    url("../../public/assets/fonts/Estonia-Regular.woff2") format("woff2");
}

@font-face {
  font-family: "FuzzyBubbles-Regular";
  src: local("FuzzyBubbles-Regular"),
    url("../../public/assets/fonts/FuzzyBubbles-Regular.woff2") format("woff2");
}

@font-face {
  font-family: "Mansalva-Regular";
  src: local("Mansalva-Regular"),
    url("../../public/assets/fonts/Mansalva-Regular.woff2") format("woff2");
}

@font-face {
  font-family: "MsMadi-Regular";
  src: local("MsMadi-Regular"),
    url("../../public/assets/fonts/MsMadi-Regular.woff2") format("woff2");
}

@font-face {
  font-family: "Mynerve-Regular";
  src: local("Mynerve-Regular"),
    url("../../public/assets/fonts/Mynerve-Regular.woff2") format("woff2");
}

@font-face {
  font-family: "QwitcherGrypen-Regular";
  src: local("QwitcherGrypen-Regular"),
    url("../../public/assets/fonts/QwitcherGrypen-Regular.woff2") format("woff2");
}

@font-face {
  font-family: "WaterBrush-Regular";
  src: local("WaterBrush-Regular"),
    url("../../public/assets/fonts/WaterBrush-Regular.woff2") format("woff2");
}

@font-face {
  font-family: "Whisper-Regular";
  src: local("Whisper-Regular"),
    url("../../public/assets/fonts/Whisper-Regular.woff2") format("woff2");
}
