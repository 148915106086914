@media only screen and (max-width: 560px) and (orientation: portrait) {
  .wrapper {
    // dashboard container
    .dashboard-container {
      .metrics-wrap {
        .metrics-container {
          background-color: transparent;
          max-height: 240px;
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          grid-template-rows: 1fr;
          gap: 6px;
          border: none;

          .action-need,
          .waiting-others,
          .expiring,
          .completed,
          .expired {
            max-width: 253px;
            background-color: white;
            height: 115px;
            border-radius: 10px;
            border: 1px solid rgba(0, 0, 0, 0.1);

            .ant-typography {
              font-size: 28px;
              font-weight: 700;
            }

            span {
              margin-top: 5px;
              font-size: 14px;
            }
          }

          .ant-divider {
            display: none;
          }
        }

        .metrics-history {
          height: calc(100% - 290px);
          .ant-table-wrapper {
            .ant-spin-nested-loading {
              height: 100%;
              .ant-spin-container {
                height: 100%;
                .ant-table {
                  height: calc(100% - 64px);

                  .ant-table-container {
                    height: 100%;
                  }
                }
              }
            }
          }
        }
      }
    }

    //design container
    .design-container {
      .header-wrapper {
        flex-wrap: wrap;
        align-items: center;
        .dash-nav {
          justify-content: center;
          min-width: fit-content;
          .already-publish {
            margin: 0px;
            white-space: nowrap;
          }
        }
      }

      .editor-container {
        .form-header {
          justify-content: space-between;
        }

        .form-container {
          .form-content {
            .pdf-list-container {
              .pdf-viewer-container {
                flex-direction: column !important;
                .pdf-toolbar {
                  width: 100%;
                  max-width: 100%;
                  height: fit-content !important;
                  flex: none;
                  display: grid;
                  grid-template-columns: 1fr 1fr;

                  .ant-divider {
                    margin: 10px 0px;
                    min-width: fit-content;
                    max-width: 160px;
                    min-height: 40px;
                    height: 40px;
                  }

                  .page-nav,
                  .page-widgets,
                  .tool-props {
                    min-width: fit-content;
                    width: 200px;
                    min-height: 40px;
                    height: 60px;
                  }

                  .tool-props {
                    overflow: auto;
                  }
                }
              }
            }
          }
        }
      }
    }

    .document-container {
      .editor-container {
        .pdf-doc-view {
          .pdf-viewer-container {
            flex-direction: column !important;
            .pdf-toolbar {
              width: 100%;
              max-width: 100%;
              height: fit-content !important;
              flex: none;
              display: grid;
              grid-template-columns: 1fr 1fr;

              .ant-divider {
                margin: 10px 0px;
                min-width: fit-content;
                max-width: 160px;
                min-height: 40px;
                height: 40px;
              }

              .page-nav,
              .page-widgets,
              .tool-props {
                min-width: fit-content;
                width: 200px;
                min-height: 40px;
                height: 60px;
              }

              .designated-boxes {
                margin: 10px 0px;
                .empty-props {
                  .ant-empty-image {
                    height: 30px !important;
                  }
                }
              }

              .tool-props {
                overflow: auto;
              }
            }
          }
        }
      }
    }
  }
}
