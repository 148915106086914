@use './styles.scss';


.section-toolbar {
    @extend .center-all;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 20px 0;
    max-height: 60px;

    .ant-btn {
        margin: 0 0 0 15px;
    }
}