* {
    box-sizing: border-box;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.tc-table {
    width: 100%;
    table-layout: fixed;
    margin-bottom: 2px;
    border-collapse: collapse;
}

.tc-table tbody, .tc-table tbody tr {
    border-radius: inherit;
}

.tc-table-cell {
    height: 42px;
    border-radius: inherit;
    outline: none;
    overflow: hidden;
    white-space: pre-wrap;
}

.tc-table-context-menu {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 10;
    width: 180px;
    max-width: 250px;
    max-height: fit-content;
    box-shadow: 0px 1px 15px -8px rgba(0, 0, 0, 0.5);
    border: 1px solid #d3d3d3;
    background-color: #fff;
    border-radius: 6px;
    max-height: 240px;
}

.tc-menu-item {
    padding: 5px 16px;
    font-size: 14px;
    margin: 0;
    height: 35px;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.tc-menu-item:hover {}


/* dialog container styles  */

.tc-dialog-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 400px;
    height: 350px;
    background-color: #fff;
    border-radius: 6px;
}

/* header styles */

.tc-dialog-container .tc-dialog-head {
    height: 15%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 16px;
    background-color: #3a8de5;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
}

.tc-dialog-container .tc-dialog-head * {
    color: #fff;
}

.tc-dialog-container .tc-dialog-head p {
    margin: 0;
    font-size: 16px;
    font-weight: 700;
    width: 80%;
}

.tc-dialog-container .tc-dialog-head button {
    width: 40px;
    height: 40px;
    padding: 5px;
    margin: 0;
    background-color: transparent;
    border-radius: 6px;
    border: none;
    cursor: pointer;
}

.tc-dialog-container .tc-dialog-head button:hover {
    opacity: 0.6;
}

/* dialog content styles */

.tc-dialog-container .tc-dialog-content {
    height: 70%;
    width: 100%;
    padding: 14px;
}


.tc-dialog-container .tc-dialog-content .tc-dialog-content-inp {
    height: fit-content
}

.tc-dialog-container .tc-dialog-content .tc-dialog-content-inp .tc-dialog-content-inp-label {
    font-size: 14px;
    font-weight: 600;
    color: grey;
    margin-bottom: 5px;
}

.tc-dialog-container .tc-dialog-content .tc-dialog-content-inp .tc-dialog-content-inp-cont {
    font-size: 14px;
    width: 100%;
    border: none;
}

.tc-picker-cont {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 16px;
}

.tc-picker-cont p {
    width: 25%;
}

.tc-picker-cont input {
    width: 25%;
    border: 1px solid #d3d3d3;
    border-radius: 6px;
    padding: 2px 5px;
    outline: none;
    background-color: transparent;
    appearance: none;
    height: 30px;
}


/* dialog footer styles */

.tc-dialog-container .tc-dialog-footer {
    width: 100%;
    height: 15%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0px 16px;
}

.tc-dialog-container .tc-dialog-footer .tc-cancel-btn,
.tc-dialog-container .tc-dialog-footer .tc-apply-btn {
    font-size: 13px;
    font-weight: 500;
    text-transform: capitalize;
    margin: 4px 6px 14px 6px;
    height: 30px;
    padding: 0px 15px;
    border-radius: 6px;
    cursor: pointer;
}

.tc-dialog-container .tc-dialog-footer .tc-cancel-btn {
    border: 1px solid grey;
    background-color: transparent;
}

.tc-dialog-container .tc-dialog-footer .tc-cancel-btn:hover {
    border-color: #3a8de5;
    color: #3a8de5;
}

.tc-dialog-container .tc-dialog-footer .tc-apply-btn {
    border: none;
    background-color: #3a8de5;
    color: #fff;
}

.tc-dialog-container .tc-dialog-footer .tc-apply-btn:hover {
    opacity: 0.8;
}

/* overlay styles */

.tc-overlay {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 100;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    overflow: hidden;
}

/* cell selection styles  */
.cell-selected {
    background-color: rgb(116 167 220 / 10%);
}