* {
  box-sizing: border-box;
  user-select: none;
}

html,
body {
  -webkit-print-color-adjust: exact;
  margin: 0;
  padding: 0;
  outline: none;
  height: 100%;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

#root {
  height: inherit;
}


