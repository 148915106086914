@media only screen and (max-width: 426px) and (orientation: portrait) {
  .wrapper {
    .signup-wrapper {
      .header-wrapper {
        flex-direction: column;
        align-items: flex-start;

        .logo-container,
        .signup-nav {
          height: 50px;
          width: 100%;
          flex: 1;
          flex-wrap: wrap;
        }

        .logo-container {
          width: 100%;
          padding: 14px 24px 0px 24px;

          img {
            object-fit: contain;
          }
        }

        .signup-nav {
          h5 {
            margin-top: 0;
          }
          .signIn-btn {
            margin-left: 24px;
            margin-top: 0px;
          }
        }
      }

      .signup-middle {
        .signup-card {
          width: 86%;
          max-width: 86%;
        }

        .signup-success-wrapper {
          .ant-result {
            padding: 48px 0px;
            .ant-result-title {
              font-size: 19px;
            }
            .ant-result-extra {
              .code-inp {
                margin: 8px;
              }
            }
          }
        }
      }
    }

    .dashboard-container {
      .metrics-wrap {
        height: calc(100% - 60px);
        .metrics-container {
          .action-need,
          .waiting-others,
          .expiring,
          .completed,
          .expired {
            .ant-typography {
              font-size: 22px;
            }

            span {
              font-size: 12px;
            }
          }
        }
      }

      .header-wrapper {
        height: 60px;
      }

      .dashboard-content {
        height: calc(100% - 60px);
        padding: 14px;

        // sign space container styles
        .sign-space-container {
          gap: 14px !important;
          justify-content: flex-start;

          .ant-space-item:nth-of-type(1) {
            .ant-space {
              gap: 0px !important;
            }
          }

          .ant-space-item:nth-of-type(2) {
            height: calc(100% - 200px);
            .ant-space {
              .ant-space-item:nth-of-type(2) {
                // tabs styles
                .ant-tabs {
                  .ant-tabs-content-holder {
                    height: calc(100% - 50px);
                    .ant-tabs-content {
                      height: 100%;
                      .ant-tabs-tabpane.ant-tabs-tabpane-active {
                        .ant-space {
                          // signature full name styles
                          .ant-space-item:nth-of-type(1) {
                            .signature-name-wrap {
                              gap: 10px !important;
                              .ant-space-item {
                                height: fit-content;
                                .ant-space {
                                  width: 100%;
                                  .ant-space-item {
                                    .email-error,
                                    .ant-input {
                                      width: 150px !important;
                                    }
                                  }
                                }
                              }

                              .ant-space-item:nth-of-type(3) {
                                .ant-space {
                                  .ant-space-item {
                                    .ant-btn {
                                      margin: 5px 0px 0px 0px !important;
                                    }
                                  }
                                }
                              }
                            }
                          }

                          // signature list ui styles
                          .ant-space-item:nth-of-type(2) {
                            height: calc(100% - 109px);
                            .ant-list {
                              height: 100%;
                              .ant-spin-nested-loading {
                                .ant-spin-container {
                                  .ant-list-items {
                                    .ant-list-item {
                                      border-radius: 10px;
                                      padding: 4px 0px;
                                      .sign-list-radio-wrap {
                                        gap: 5px !important;
                                        justify-content: flex-start;

                                        .ant-space-item:nth-of-type(2),
                                        .ant-space-item:nth-of-type(3) {
                                          .sign-name-wrap {
                                            width: 100px;
                                            height: 40px;
                                            .sign-content-wrap {
                                              .signed-by {
                                                display: none;
                                              }

                                              .sign-name {
                                              }

                                              .sign-serial {
                                                display: none;
                                              }
                                            }
                                          }

                                          .sign-border-curve {
                                            display: none;
                                          }
                                        }
                                        .ant-space-item:nth-of-type(3) {
                                          .sign-initial-wrap {
                                            //
                                          }
                                        }
                                      }
                                    }
                                    .ant-list-item:last-of-type {
                                      margin-bottom: 20px;
                                    }

                                    .ant-list-item:nth-child(odd) {
                                      background-color: #e9ebff;
                                    }

                                    .ant-list-item:nth-child(even) {
                                      background-color: #fff;
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }

                        .sign-upload-wrap {
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    //design container
    .design-container {
      .header-wrapper {
        flex-wrap: nowrap !important;
        flex-direction: column;
        align-items: flex-start !important;
        .dash-nav {
          width: 100%;
          .already-publish {
          }
        }
      }
    }
  }
}
